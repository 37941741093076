import { Circle } from "progressbar.js";
import Swiper from "swiper";
import SwiperCore, { Navigation, Controller, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Controller, Autoplay]);

(function () {
  const quotesSlider = document.querySelector("#quotes-slider");
  if (!quotesSlider) {
    return;
  }
  const quoteThumbnails = document.querySelectorAll(".quote-thumbnail");

  const circles = [];
  for (let thumb of quoteThumbnails) {
    const progressBarEl = thumb.querySelector(".progress-bar");
    const circle = new Circle(progressBarEl, {
      color: "#FFAA00",
      strokeWidth: 9,
      duration: 5000,
      easing: "linear",
    });
    circles.push(circle);
  }

  let isPaused = false;

  const pauseAutoplay = (activeIndex) => {
    const index = (activeIndex + circles.length - 1) % circles.length;
    circles[index].pause();
  };

  const resumeAutoplay = (activeIndex) => {
    const index = (activeIndex + circles.length - 1) % circles.length;
    circles[index].resume();
  };

  const moveToSlide = (prevIndex, nextIndex, swiper) => {
    const prev = (prevIndex + circles.length - 1) % circles.length;
    const next = (nextIndex + circles.length - 1) % circles.length;
    circles[prev].cancel();
    circles[prev].set(0);
    circles[next].set(1);
    circles[next].animate(2, {}, () => {
      swiper.slideNext();
    });
    if (isPaused) {
      pauseAutoplay(nextIndex);
    }
  };

  // circle.animate(2);

  const swiper = new Swiper("#quotes-slider", {
    direction: "horizontal",
    loop: true,
    slidesPerView: 1,
    spaceBetween: 180,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      slideChangeTransitionEnd: (swiper) => {
        moveToSlide(swiper.previousIndex, swiper.activeIndex, swiper);
      },
    },
  });

  quotesSlider.addEventListener("mouseenter", () => {
    pauseAutoplay(swiper.activeIndex);
    isPaused = true;
  });
  quotesSlider.addEventListener("touchstart", () => {
    pauseAutoplay(swiper.activeIndex);
    isPaused = true;
  });
  quotesSlider.addEventListener("mouseleave", () => {
    resumeAutoplay(swiper.activeIndex);
    isPaused = false;
  });
  quotesSlider.addEventListener("touchend", () => {
    resumeAutoplay(swiper.activeIndex);
    isPaused = false;
  });

  for (let [i, thumb] of quoteThumbnails.entries()) {
    thumb.addEventListener("click", () => {
      swiper.slideTo(i + 1);
    });
  }

  const breakpoint = window.matchMedia("(min-width:767px)");
  // keep track of swiper instances to destroy later
  let mySwiper;
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (mySwiper !== undefined) {
        mySwiper.destroy(true, true);
        swiper.controller.control = null;
      }
      // or/and do nothing
      return;
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const enableSwiper = function () {
    mySwiper = new Swiper(".quote-thumbnails", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: "17%",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      controller: {
        control: swiper,
      },
    });
    swiper.controller.control = mySwiper;
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  // keep an eye on viewport size changes
  breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  breakpointChecker();
})();
